'use client';

import { faLink, faX } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalContent } from '@pickleballinc/react-ui';
import React, { useEffect, useState } from 'react';

import { FORBIDEN_ROUTE, FORBIDEN_ROUTE_2 } from '@/components/ConnectDUPRModal/constants';
import { getShouldShowModal, setShouldShowModal } from '@/components/ConnectDUPRModal/helpers';
import { GetDuprPlayerDataRes } from '@/utils/actions/players/edit/getDuprPlayerData';
import getCDNURL from '@/utils/helpers/getCDNURL';

interface ConnectDuprModalProps {
	duprData: GetDuprPlayerDataRes;
	isLoggedIn: boolean;
}

const ConnectDUPRModal = ({ duprData, isLoggedIn }: ConnectDuprModalProps) => {
	const [modalOpened, setModalOpened] = useState(false);

	useEffect(() => {
		const location = window.location.pathname;
		if (location.startsWith(FORBIDEN_ROUTE) || location.endsWith(FORBIDEN_ROUTE_2)) return;

		const userConnectedDUPR = duprData.duprId ? true : false;

		const shouldShowModal = getShouldShowModal(userConnectedDUPR, isLoggedIn);
		setModalOpened(shouldShowModal);

		if (shouldShowModal) {
			const body = document.getElementsByTagName('body')[0];
			if (body) {
				body.classList.add('!overflow-y-hidden');
			}
		}
	}, []);

	if (!modalOpened) return null;

	const toggleModal = () => {
		if (modalOpened) {
			setShouldShowModal();
		}
		setModalOpened(!modalOpened);
		const body = document.getElementsByTagName('body')[0];
		if (body) {
			body.classList.remove('!overflow-y-hidden');
		}
	};

	const closeModal = () => {
		setShouldShowModal();
		setModalOpened(false);
		const body = document.getElementsByTagName('body')[0];
		if (body) {
			body.classList.remove('!overflow-y-hidden');
		}
	};

	const handleConnectMyDUPRClick = () => {
		closeModal();
		window.location.href = connectDUPRLink;
	};

	const isLocalhost = process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME?.startsWith('localhost');
	const connectDUPRLink = `http${isLocalhost ? '' : 's'}://${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/players/${duprData.userSlug}/profile/connect-dupr`;

	return (
		<Modal open={modalOpened} onOpenChange={toggleModal}>
			<ModalContent className="flex w-[calc(100%-16px)] max-w-[480px] flex-col gap-4 !overflow-hidden !p-4 !outline-none">
				<div className="relative border-b pb-2">
					<h2 className="pr-4 text-[16px] font-semibold leading-[22px] text-gray-900">Please connect a DUPR account</h2>
					<div
						className="absolute -right-2 top-0 flex size-6 items-center justify-center rounded-md border border-solid border-gray-300 bg-white p-1 hover:cursor-pointer hover:bg-gray-100"
						onClick={closeModal}
					>
						<FontAwesomeIcon icon={faX} className="text-gray-400" height={20} />
					</div>
				</div>
				<div className="flex items-center justify-center gap-4 pb-4">
					<img
						src={getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/dupr-logo.png`, 127, 40)}
						alt="DUPR logo"
						width={127}
						height={40}
					/>
					<FontAwesomeIcon icon={faLink} className="text-gray-400" size="lg" />
					<img
						src={getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/static/Pickleball-Logomark-PNG-Large.png`, 56, 40)}
						alt="Pickleball.com"
						width={56}
						height={40}
					/>
				</div>
				<div className="flex w-full flex-col gap-1.5 pb-4">
					<p className="text-sm font-medium text-gray-700">
						{`DUPR is pickleball’s most accurate global rating system and Pickleball.com’s official rating system. When connecting your DUPR
						account to Pickleball.com:`}
					</p>
					<ul className="list-disc pl-4 sm:pl-6">
						<li className="text-left text-sm font-medium text-gray-700">
							Directors can sync match results with DUPR. Your results will be automatically imported into DUPR.
						</li>
						<li className="text-left text-sm font-medium text-gray-700">Your DUPR rating will be displayed on Pickleball.com.</li>
					</ul>
				</div>
				<div className="flex items-center gap-4 sm:gap-6">
					<Button
						variant="primary"
						className="flex justify-center px-2.5 py-2 text-sm focus:!shadow-[unset] sm:w-[200px] sm:px-4 sm:py-2.5 sm:text-[16px]"
						onClick={handleConnectMyDUPRClick}
					>
						Connect my DUPR
					</Button>
					<Button variant="link" className="underlined text-sm sm:text-[16px]" onClick={closeModal}>
						Remind me later
					</Button>
				</div>
			</ModalContent>
		</Modal>
	);
};

export default ConnectDUPRModal;
