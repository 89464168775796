'use client';

import { Button } from '@pickleballinc/react-ui';
import React, { useEffect, useState } from 'react';

import ClipboardIcon from '@/components/ClipboardIcon/ClipboardIcon';

interface ImpersonationBarProps {
	firstName: string;
	lastName: string;
	email: string;
	uuid: string;
	pbUuid: string;
}

const ImpersonationBar = ({ firstName, lastName, email, uuid, pbUuid }: ImpersonationBarProps) => {
	const [isFixed, setIsFixed] = useState(false);
	const stopImpersonationURL = `${process.env.NEXT_PUBLIC_SSO_ENDPOINT}/sessions/i/cookie/revoke`;

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY >= 500) {
				setIsFixed(true);
			} else {
				setIsFixed(false);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={`${isFixed ? 'fixed' : 'sticky'} top-0 z-[101] flex w-full justify-center bg-green-600`}>
			<div className="flex w-full items-center justify-between gap-4 p-2 xl:max-w-screen-xl xl:px-0">
				<div className="flex items-center gap-3" data-testid="impersonation-text">
					<p className="text-sm text-white">
						{`You're`} currently impersonating <span className="font-semibold">{`${firstName} ${lastName}`}</span> ({email})
					</p>
					<ClipboardIcon content={`${firstName} ${lastName} (${email}), uuid: ${uuid}, pb_uuid: ${pbUuid}`} />
				</div>
				<a href={stopImpersonationURL} data-testid="impersonation-link-stop">
					<Button variant="secondary" size="xs" className="bg-white !px-2 !py-1 !text-sm" data-testid="impersonation-button-stop">
						Stop
					</Button>
				</a>
			</div>
		</div>
	);
};

export default ImpersonationBar;
